import React, { useState } from "react";
import {Link} from 'gatsby'
import Slide from "react-reveal/Slide";
import Ogham from "../../images/logos/ogham.svg";

const AudioPlayer = ({ url, transcript }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="w-full bg-gradient-to-t from-waterford-ocean to-transparent pt-8 pb-6">
      <button
        className="cyan-audio rounded-full h-28 w-28 bg-waterford-cyan hover:bg-opacity-100 mx-auto ml-44 md:ml-auto"
        onClick={() => setIsActive(!isActive)}
      >
        <div className="animate-pulse transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-14 rounded-full mx-auto ring ring-waterford-cyan"></div>
      </button>
      {isActive && (
        <div className="absolute top-100 w-full py-6 px-4">
          <Slide bottom>
            <div className="grid grid-cols-6">

            <audio autoPlay controls className="w-full col-span-5 bg-white rounded-l-full">
              <source src={url} type="audio/mp3" />
            </audio>
               <Link className="bg-waterford-blue py-1 rounded-r-full"
                 to={transcript}
                 title="Transcription in English">
                    <img className="h-11 py-2 m-auto" src={Ogham} />
               </Link>
             </div>

          </Slide>
        </div>
      )}
    </div>
  );
};

export default AudioPlayer;
